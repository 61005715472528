.services-header {
    font-size: 20px;
    color: var(--color-zb-new-brown);
    margin-bottom: -50px;
}

@media(max-width: 1200px) {
    .services-header {
        margin-bottom: 0;
    }
}