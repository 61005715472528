.menu-slideout {
    position: fixed;
    background: white;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
    display: flex;
    flex-direction: column;
    padding: 50px;
    gap: 20px;
    transition: right .5s ease-in-out;
}

.menu-slideout.open {
    right: 0;
}

.menu-slideout.close {
    right: -300px;
}

.grey-overlay {
    position: fixed;
    background-color: rgba(50, 50, 50, 0.75);
    z-index: 150;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.grey-overlay.open {
    display: block;
}

.grey-overlay.close {
    display: none;
}

.close-x {
    position: absolute;
    top: 10px;
    left: 10px;
}

@media (min-width: 840px) {
    .grey-overlay {
        display: none !important;
    }

    .menu-slideout {
        display: none !important;
    }
}