:root {
  --size-size-large: 144px;
  --size-size-small: 48px;
  --size-size-medium: 96px;
  --size-size-xlarge: 192px;
  --size-size-xsmall: 16px;
  --space-space-unit: 16px;
  --color-gray-500: #595959;
  --color-gray-700: #999999;
  --color-gray-900: #D9D9D9;
  --color-danger-300: #A22020;
  --color-danger-500: #BF2626;
  --color-danger-700: #E14747;
  --color-gray-black: #000000;
  --color-gray-white: #FFFFFF;
  --color-primary-100: #14302E;
  --color-primary-300: #8db0ae;
  --color-secondary: #F69C2E;
  --color-success-300: #199033;
  --color-success-500: #32A94C;
  --color-success-700: #4CC366;
  --size-size-xxlarge: 288px;
  --size-size-maxwidth: 1400px;
  --radius-radius-round: 50%;
  --space-space-halfunit: 8px;
  --space-space-sixunits: 96px;
  --space-space-twounits: 32px;
  --radius-radius-radius2: 2px;
  --radius-radius-radius4: 4px;
  --radius-radius-radius8: 8px;
  --space-space-fiveunits: 80px;
  --space-space-fourunits: 64px;
  --space-space-threeunits: 48px;
  --space-space-oneandhalfunits: 24px;
  /* new */
  --color-zb-new-blue: #55619b;
  --color-zb-new-green: #8cbd51;
  --color-zb-new-brown: #8e4820;
  --color-zb-new-grey: #a8aaad;
}