.home-studies {
    display: flex;
    padding: 50px;
}

.navigation {
    flex: 1;
}

.article {
    flex: 2;
}

.spacer {
    height: 20px;
}