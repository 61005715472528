.cta {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  text-align: center;
  z-index: 1000;
}

.cta p {
  margin: 0;
  color: white; /* Default text color, adjust as needed */
}
