li {
  text-transform: uppercase;
  font-size: 24px;
  line-height: 1.5;
  margin-left: 40px;
}

input::placeholder {
  color: var(--color-zb-new-green);
}

li .capital {
  font-size: 26px;
}

.dead-bug {
  position: absolute;
  right: 20px;
  top: 10px;
}

.input {
  color: var(--color-gray-white);
  cursor: auto;
  outline: none;
  padding: 0.5rem 1rem;
  border-color: var(--color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--color-gray-white);
}

.textarea {
  color: var(--color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--color-gray-white);
}

.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}

.list-item {
  display: list-item;
}

.teleport-show {
  display: flex !important;
}

.button-main {
  gap: var(--space-space-halfunit);
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  font-style: normal;
  transition: 0.3s;
  align-items: center;
  font-family: Inter;
  font-weight: 600;
  padding-top: var(--space-space-unit);
  padding-left: var(--space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--space-space-unit);
  justify-content: center;
  background-color: var(--color-zb-new-green);
  max-height: 51px;
}

.button-main:hover {
  opacity: 0.75;
}

.quick-links {
  gap: var(--space-space-unit);
  pointer-events: none;
  width: 100%;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  border-color: #ffffff;
  flex-direction: column;
  border-left-width: 1px;
  padding-left: var(--space-space-threeunits);
}

.quick-links:hover {
  opacity: 0.5;
}

.read-more {
  gap: var(--space-space-halfunit);
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
}

.read-more:hover {
  opacity: 0.5;
}

.button-white {
  color: #000000;
  background-color: #ffffff;
}

.symptom {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--space-space-unit);
  padding-left: var(--space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--space-space-oneandhalfunits);
  padding-bottom: var(--space-space-unit);
  justify-content: center;
  background-color: #E9E9EB;
}

.symptom:hover {
  opacity: 0.5;
}

.letter {
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--space-space-unit);
  max-width: 60px;
  transition: 0.3s;
  align-items: center;
  aspect-ratio: 1;
  padding-left: var(--space-space-oneandhalfunits);
  border-radius: var(--radius-radius-round);
  padding-right: var(--space-space-oneandhalfunits);
  justify-content: center;
  background-color: #E9E9EB;
}

.letter:hover {
  opacity: 0.5;
}

.book-type {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  padding-top: var(--space-space-unit);
  border-color: var(--color-gray-black);
  border-width: 1px;
  padding-left: var(--space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--space-space-oneandhalfunits);
  padding-bottom: var(--space-space-unit);
}

.book-type:hover {
  color: white;
  background-color: black;
}

.book-input {
  width: 100%;
  padding-top: 0px;
  border-color: #B4B9C6;
  border-width: 0px;
  padding-left: var(--space-space-unit);
  border-radius: 0px;
  padding-right: var(--space-space-unit);
  padding-bottom: var(--space-space-unit);
  background-color: transparent;
  border-bottom-width: 1px;
}

.arrow {
  width: 60px;
  cursor: pointer;
  object-fit: cover;
  transition: 0.3s;
}

.arrow:hover {
  opacity: 0.5;
}

.legal-link {
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 12px;
  transition: 0.3s;
}

.legal-link:hover {
  opacity: 0.5;
}

.social {
  width: 40px;
  cursor: pointer;
  height: 40px;
  display: flex;
  padding: var(--space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(236, 233, 234, 0.2);
}

.social:hover {
  opacity: 0.5;
}

.letter-active {
  color: white;
  background-color: var(--color-primary-100);
}

.search-result {
  width: 100%;
  display: flex;
  padding-top: var(--space-space-unit);
  padding-left: var(--space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--space-space-oneandhalfunits);
  padding-bottom: var(--space-space-unit);
  justify-content: center;
  background-color: #E9E9EB;
}

.result-text {
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  white-space: nowrap;
}

.more-results {
  color: white;
  background-color: var(--color-primary-100);
}

.invisible {
  opacity: 0;
  animation: fade-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  transform-origin: 0 50%;
}

.Content {
  font-size: 16px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}

.Heading {
  font-size: 32px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}

@media(max-width: 1050px) {
  .dead-bug {
    height: 400px;
    top: 150px;
  }
}

@media(max-width: 1200px) {
  .letter {
    max-width: calc(100% / 6 - 20px);
    padding-top: var(--space-space-unit);
    padding-left: var(--space-space-unit);
    padding-right: var(--space-space-unit);
    padding-bottom: var(--space-space-unit);
  }
}



@media (max-width: 880px) {
  .dead-bug {
    height: 300px;
    top: 300px;
  }
}

@media (max-width: 810px) {
  .dead-bug {
    top: 100px;
  }
}

@media(max-width: 767px) {
  .arrow {
    width: 40px;
  }

  .dead-bug {
    top: 0;
  }

  .home-lower {
    width: 500px !important;
  }
}

@media (max-width: 570px) {
  .dead-bug {
    height: 200px;
    right: 10px;
    top: 600px;
  }

  .hero-body {
    width: 100% !important;
  }
}

@media (max-width: 500px) {
  .dead-bug {
    top: 700px;
  }

  .home-lower {
    width: 300px !important;
  }
}

.secondary-header {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
  color: var(--color-zb-new-green)
}

.hero-body {
  font-weight: 600;
  font-size: 20px;
  color: var(--color-zb-new-green);
  width: 60%;
}

.epa {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.epa.white {
  color: white;
}

.epa.black {
  color: black;
}

.guarantee {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 60px;
}

#privacy {
  padding: 2% 20%
}

#privacy p {
  text-indent: 20px;
}

#privacy ul {
  list-style-position: inside;
}

#privacy li {
  padding-left: 30px;
}

.spacer {
  height: 20px;
}

.underline {
  text-decoration: underline;
}